import StartPageRenderer from '@jetshop/ui/StartPageRenderer';
import React, { useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { styled } from 'linaria/react';
import MaxWidth from '../Layout/MaxWidth';
import LoadingPage from '../LoadingPage';
import startPageQuery from './StartPageQuery.gql';
import contentComponents from '../CustomContent/elementMap';
import { theme } from '../Theme';
import { TrackPageLoad } from '../DataLayer/DataLayer';

const StartPageWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;

  &.transparent-header {
    margin-top: -88px;

    ${theme.below.lg} {
      margin-top: -74px;
    }
  }
`;

export const MainSectionWrapper = styled(MaxWidth)`
  width: 100%;
  align-self: center;
  padding-left: 2rem;
  padding-right: 2rem;

  ${theme.below.sm} {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const StartPage = ({ startPageId, headerIsOpaque, setHeaderAsOpaque }) => {
  const result = useQuery(startPageQuery, {
    variables: { startPageId: startPageId }
  });
  const { loading } = result;

  useEffect(() => {
    !!setHeaderAsOpaque && setHeaderAsOpaque(false);
  });

  if (loading || !result?.data) return <LoadingPage />;

  return (
    <StartPageWrapper
      className={headerIsOpaque ? 'opaque-header' : 'transparent-header'}
    >
      <TrackPageLoad pageType={'Startsida'} />
      <StartPageRenderer
        result={result}
        startPageComponents={contentComponents}
        LoadingPage={LoadingPage}
      />
    </StartPageWrapper>
  );
};

export default StartPage;
